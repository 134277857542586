<template>
	<a-config-provider :locale="ru_RU">
		<router-view @vue:updated="Utils.setFormItemsOffset('labelOffset', 'buttonOffset')" />
	</a-config-provider>
</template>

<script lang="ts" setup>
import { watch } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { Utils } from '@/core/utils'
import ru_RU from 'ant-design-vue/es/locale/ru_RU'

const { width } = useWindowSize()
watch(width, () => Utils.setFormItemsOffset('labelOffset', 'buttonOffset'))
</script>
