import { useMediaQuery } from '@vueuse/core'

export class Utils {
	static setFormItemsOffset(labelClassName: string, buttonClassName: string) {
		if (useMediaQuery('(max-width: 575px)').value) return document.getElementById(buttonClassName)?.setAttribute('style', 'padding-left: 0px')
		const elements: Element[] = Array.from(document.getElementsByClassName(labelClassName))
		const labelElements: Element[] = []
		elements.forEach(i => labelElements.push(i.getElementsByTagName('label')[0]))
		const widestLabelElement = Math.max(...labelElements.map(i => i.clientWidth))
		labelElements.forEach(i => i.setAttribute('style', `width: ${widestLabelElement}px;`))
		document.getElementById(buttonClassName)?.setAttribute('style', `padding-left: ${widestLabelElement}px`)
	}
	static fileToBase64 = (file: File) =>
		new Promise<string>((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result as string)
			reader.onerror = reject
		})
}
