import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { WebTrackService } from '@/modules/pages/webTrack/serviceCreate'
import { IWebTrackRoute } from '@/modules/pages/webTrack/type'

const routes: Array<RouteRecordRaw> = [
	{
		path: '',
		redirect: '/account/index',
		component: () => import('@/modules/layouts/main.vue'),
		children: [
			{ path: '/account/index', component: () => import('@/modules/pages/account/index/index.vue'), meta: { auth: true, webTrack: { moduleName: 'account', functionName: 'accountOpenIndex', needTraceId: false } } },
			{ path: '/account/page/:id?', component: () => import('@/modules/pages/account/page/page.vue'), meta: { auth: true, webTrack: { moduleName: 'account', functionName: 'accountOpenPage', needTraceId: true } } },
			{ path: '/user/index', component: () => import('@/modules/pages/user/index/index.vue'), meta: { auth: true, webTrack: { moduleName: 'user', functionName: 'userOpenIndex', needTraceId: false } } },
			{ path: '/user/page/:id?', component: () => import('@/modules/pages/user/page/page.vue'), meta: { auth: true, webTrack: { moduleName: 'user', functionName: 'userOpenPage', needTraceId: true } } },
			{ path: '/track/index', component: () => import('@/modules/pages/track/index/index.vue'), meta: { auth: true } },
			{ path: '/webTrack/index', component: () => import('@/modules/pages/webTrack/index/index.vue'), meta: { auth: true } }
		]
	},
	{
		path: '',
		component: () => import('@/modules/layouts/empty.vue'),
		children: [
			{ path: '/auth/login', component: () => import('@/modules/pages/auth/login/login.vue'), meta: { auth: false, webTrack: { moduleName: 'login', functionName: 'loginOpenPage', needTraceId: true } } },
			{ path: '/auth/register', component: () => import('@/modules/pages/auth/register/register.vue'), meta: { auth: false, webTrack: { moduleName: 'register', functionName: 'registerOpenPage', needTraceId: true } } }
		]
	}
]

const router = () => {
	const router = createRouter({
		history: createWebHistory(process.env.BASE_URL),
		routes
	})
	const authStore = useAuthStore()

	router.beforeEach((to, from, next) => {
		const requiredAuth = to.matched.some(r => r.meta.auth)
		const token = authStore.token
		if (requiredAuth && !token) next('/auth/login')
		else next()
	})
	router.afterEach((to, from) => {
		if (to.path == from.path) return
		if (to.meta?.webTrack) {
			const webTrackService = new WebTrackService()
			const webTrackRoute = to.meta.webTrack as IWebTrackRoute
			webTrackService.create(webTrackRoute)
		}
	})
	return router
}

export default router
